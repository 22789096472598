import { css, querySelector } from "trivial-dom";
import { deriveSinglePanelOffsetFactory } from "./deriveSinglePanelOffsetFactory";
import { cubed, fixedFactory, inverse, linear, set } from "@porkchopsandwich/easers";
import { onScrollChange } from "./scrolling/onScrollChange";

interface IConfig {
    offsetLeft?: boolean;
    offsetTop?: boolean;
    lock?: boolean;
}

export const panelCopy = () => {
    const bootstrap = (config: IConfig) => {
        if (!config.lock && !config.offsetLeft && !config.offsetTop) {
            return;
        }

        const copyElements = querySelector<HTMLDivElement>(".panels .copy");
        const firstPanelPage = 1;

        copyElements.forEach((copyElement) => {
            css(copyElement, {
                position: "fixed",
            });
        });

        const deriveLeftOffset = deriveSinglePanelOffsetFactory(firstPanelPage, inverse(cubed), cubed, -100, 0, -100);

        const topOnEaser = config.lock ? set([linear, 0, 0.8], [fixedFactory(1), 0.8, 1]) : linear;
        const topOffEaser = config.lock ? set([fixedFactory(0), 0, 0.2], [linear, 0.2, 1]) : linear;

        const deriveTopOffset = deriveSinglePanelOffsetFactory(firstPanelPage, topOnEaser, topOffEaser, 100, 0, -100);

        onScrollChange.onScrollChange((scrollState) => {
            copyElements.forEach((copyElement, index) => {
                const top = config.offsetTop ? `${deriveTopOffset(scrollState.currentPage, index)}vh` : undefined;
                const left = config.offsetLeft ? `${deriveLeftOffset(scrollState.currentPage, index)}vw` : undefined;

                css(copyElement, {
                    top,
                    left,
                });
            });
        });
    };

    return {
        bootstrap,
    };
};
