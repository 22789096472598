import { cubed, inverse, linear } from "@porkchopsandwich/easers";
import { css, querySelector } from "trivial-dom";
import { deriveSinglePanelOffsetFactory } from "./deriveSinglePanelOffsetFactory";
import { deriveTotalPanelsOffsetFactory } from "./deriveTotalPanelsOffsetFactory";
import { lockedEaser } from "./easing/lockedEaser";
import { onScrollChange } from "./scrolling/onScrollChange";

interface IConfig {
    offsetRight?: boolean;
    fade?: boolean;
}

export const panelBackgrounds = () => {
    const bootstrap = (config: IConfig) => {
        const backgroundElements = querySelector<HTMLDivElement>(".panels .background");
        const firstPanelPage = 1;

        // Make them fixed
        backgroundElements.forEach((backgroundElement) => {
            css(backgroundElement, {
                position: "fixed",
                height: "100vh",
                bottom: "auto",
                left: "auto",
                width: "100vw",
            });
        });

        // Use the top to position the elements in place as though they were being scrolled like normal
        const deriveTop = deriveTotalPanelsOffsetFactory(1, 5, linear, linear);

        // Use the right offset to animate them in horizontally RTL
        const rightOnEaser = lockedEaser(inverse(cubed));
        const rightOffEaser = lockedEaser(cubed);
        const deriveRight = deriveSinglePanelOffsetFactory(firstPanelPage, rightOnEaser, rightOffEaser, -100, 0, -100);
        const deriveOpacity = lockedEaser(linear);

        onScrollChange.onScrollChange((scrollState) => {
            const currentTopPanel = Math.floor(scrollState.currentPage) - 1;
            const currentBottomPanel = currentTopPanel + 1;
            const panelProgress = scrollState.currentPage - 1 - currentTopPanel;

            backgroundElements.forEach((backgroundElement, index) => {
                const top = `${deriveTop(scrollState.currentPage)}vh`;
                let right = `${deriveRight(scrollState.currentPage, index)}vw`;
                if (index === 0 && scrollState.currentPage < firstPanelPage) {
                    right = `0vw`;
                } else if (index === backgroundElements.length - 1 && scrollState.currentPage > backgroundElements.length - 1 + firstPanelPage) {
                    right = `0vw`;
                }
                if (!config.offsetRight) {
                    right = `0vw`;
                }

                let opacityValue = 0;
                let zIndex = 1;
                if (index === currentTopPanel || index === 0) {
                    zIndex = 2;
                    opacityValue = 1;
                } else if (index === currentBottomPanel) {
                    zIndex = 3;
                    opacityValue = deriveOpacity(panelProgress);
                }

                css(backgroundElement, {
                    top,
                    right,
                    opacity: `${opacityValue}`,
                    zIndex,
                });
            });
        });
    };

    return {
        bootstrap,
    };
};
