export interface IOnScrollChangeEvent {
    contentHeight: number;
    viewportHeight: number;
    topOffset: number;
    bottomOffset: number;
    pageCount: number;
    topFraction: number;
    bottomFraction: number;
    middleFraction: number;
    currentPage: number;
    relativeProgress: number;
}

export interface IOnScrollChange {
    onScrollChange(listener: ScrollChangeListener): ScrollChangeUnsubscriber;
    onPageChange(listener: ScrollChangeListener): ScrollChangeUnsubscriber;
    getPageCount(): number;
    updateDimensions(): void;
}

export type ScrollChangeListener = (event: IOnScrollChangeEvent) => void;

export type ScrollChangeUnsubscriber = () => void;

export type ContentHeightGetter = () => number;

export type CurrentOffsetGetter = () => number;

export type ViewportHeightGetter = () => number;

const contentHeightGetterFactory = (): ContentHeightGetter => {
    return () => {
        return document.body.offsetHeight;
    };
};

const currentOffsetGetterFactory = (): CurrentOffsetGetter => {
    return () => {
        return window.pageYOffset;
    };
};

const viewportHeightGetterFactory = (): ViewportHeightGetter => {
    return () => {
        return window.innerHeight;
    };
};

export const onScrollChangeFactory = (ctx: EventTarget = window, contentHeightGetter: ContentHeightGetter = contentHeightGetterFactory(), currentOffsetGetter: CurrentOffsetGetter = currentOffsetGetterFactory(), viewportHeightGetter: ViewportHeightGetter = viewportHeightGetterFactory()): IOnScrollChange => {
    let viewportHeight = 0;
    let contentHeight = 0;
    let pageCount = 0;
    let currentPage: number | undefined;
    const scrollChangeListeners = new Set<ScrollChangeListener>();
    const pageChangeListeners = new Set<ScrollChangeListener>();

    const updateDimensions = (): void => {
        viewportHeight = viewportHeightGetter();
        contentHeight = contentHeightGetter();
        pageCount = Math.floor(contentHeight / viewportHeight);
    };

    const onScroll = (): void => {
        const topOffset = Math.min(currentOffsetGetter(), contentHeight - viewportHeight);
        const bottomOffset = topOffset + viewportHeight;

        const divider = 2;
        const topFraction = topOffset / contentHeight;
        const bottomFraction = bottomOffset / contentHeight;
        const middleFraction = (topFraction + bottomFraction) / divider;
        const nextPage = topFraction * pageCount;
        const progressDenominator = contentHeight - (bottomOffset - topOffset);
        const relativeProgress = progressDenominator > 0 ? topOffset / progressDenominator : 0;

        const event: IOnScrollChangeEvent = {
            contentHeight,
            viewportHeight,
            topOffset,
            bottomOffset,
            pageCount,
            topFraction,
            bottomFraction,
            middleFraction,
            currentPage: nextPage,
            relativeProgress,
        };

        scrollChangeListeners.forEach((listener) => {
            listener(event);
        });

        if (nextPage !== currentPage) {
            pageChangeListeners.forEach((listener) => {
                listener(event);
            });
            currentPage = nextPage;
        }
    };

    ctx.addEventListener("resize", updateDimensions);

    updateDimensions();

    ctx.addEventListener("scroll", onScroll);

    const onScrollChange = (listener: ScrollChangeListener): ScrollChangeUnsubscriber => {
        scrollChangeListeners.add(listener);

        onScroll();

        return (): void => {
            scrollChangeListeners.delete(listener);
        };
    };

    const onPageChange = (listener: ScrollChangeListener): ScrollChangeUnsubscriber => {
        pageChangeListeners.add(listener);

        onScroll();

        return (): void => {
            pageChangeListeners.delete(listener);
        };
    };

    const getPageCount = (): number => {
        return pageCount;
    };

    return {
        onScrollChange,
        onPageChange,
        getPageCount,
        updateDimensions,
    };
};
