import { ready } from "trivial-dom";
import { downArrows } from "./downArrows";
import { headerLogo } from "./headerLogo";
import { panelBackgrounds } from "./panelBackgrounds";
import { panelCopy } from "./panelCopy";
import { panelMask } from "./panelMask";

ready(async () => {
    await headerLogo().bootstrap();
    await panelMask().bootstrap();
    await panelCopy().bootstrap({});
    await panelBackgrounds().bootstrap({ fade: true });
    await downArrows().bootstrap();
});
