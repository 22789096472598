import { addClasses, querySelectorOne, ready } from "trivial-dom";

export const headerLogo = () => {
    const bootstrap = () => {
        ready(() => {
            const element = querySelectorOne<HTMLDivElement>("header .logo");
            if (!element) {
                return;
            }

            addClasses(element, "appear");
        });
    };

    return { bootstrap };
};
