import { EasingGraph, toRange } from "@porkchopsandwich/easers";

export const deriveTotalPanelsOffsetFactory = (onPage: number, offPage: number, onEaser: EasingGraph, offEaser: EasingGraph, beforePosition = 100, onPosition = 0, afterPosition = -100) => {
    const beforeOnPage = onPage - 1;
    const afterOffPage = offPage + 1;
    return (currentPage: number): number => {
        if (currentPage < beforeOnPage) {
            return beforePosition;
        } else if (currentPage < onPage) {
            return toRange(onEaser(currentPage - beforeOnPage), beforePosition, onPosition);
        } else if (currentPage < offPage) {
            return onPosition;
        } else if (currentPage < afterOffPage) {
            return toRange(offEaser(currentPage - offPage), onPosition, afterPosition);
        } else {
            return afterPosition;
        }
    };
};
