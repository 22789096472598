import { EasingGraph, toRange } from "@porkchopsandwich/easers";

export const deriveSinglePanelOffsetFactory = (firstPanelPage: number, onEaser: EasingGraph, offEaser: EasingGraph, beforePosition = 100, onPosition = 0, afterPosition = -100) => {
    return (currentScrollPage: number, panelIndex: number): number => {
        const panelIsAboutToScrollOn = panelIndex + firstPanelPage - 1;
        const panelIsOn = panelIndex + firstPanelPage;
        const panelIsFinishedScrollingOff = panelIndex + firstPanelPage + 1;

        if (currentScrollPage < panelIsAboutToScrollOn) {
            return beforePosition;
        } else if (currentScrollPage < panelIsOn) {
            return toRange(onEaser(currentScrollPage - panelIsAboutToScrollOn), beforePosition, onPosition);
        } else if (currentScrollPage < panelIsFinishedScrollingOff) {
            return toRange(offEaser(currentScrollPage - panelIsOn), onPosition, afterPosition);
        } else {
            return afterPosition;
        }
    };
};
