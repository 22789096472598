import { linear } from "@porkchopsandwich/easers";
import { css, querySelector } from "trivial-dom";
import { deriveSinglePanelOffsetFactory } from "./deriveSinglePanelOffsetFactory";
import { lockedEaser } from "./easing/lockedEaser";
import { onScrollChange } from "./scrolling/onScrollChange";

export const downArrows = () => {
    const bootstrap = async () => {
        const elements = querySelector<HTMLAnchorElement>(".down-arrow");

        if (elements.length === 0) {
            return;
        }

        elements.forEach((element) => {
            css(element, {
                position: "fixed",
            });
        });

        const easer = lockedEaser(linear);
        const deriveBottomOffset = deriveSinglePanelOffsetFactory(0, easer, easer, -100, 0, -100);

        onScrollChange.onScrollChange((scrollState) => {
            elements.forEach((element, index) => {
                const bottom = `${deriveBottomOffset(scrollState.currentPage, index)}vh`;

                css(element, {
                    bottom,
                });
            });
        });
    };

    return { bootstrap };
};
