import { linear, toRange } from "@porkchopsandwich/easers";
import { css, findForceOne, querySelectorOne } from "trivial-dom";
import { deriveTotalPanelsOffsetFactory } from "./deriveTotalPanelsOffsetFactory";
import { lockedEaser } from "./easing/lockedEaser";
import { onScrollChange } from "./scrolling/onScrollChange";

// const rotationToRelativeScaleIntensity = (rotation: number): number => {
//     let scale = rotation % 180;
//     while (scale > 90) {
//         scale -= 180;
//     }
//     scale = Math.abs(scale);
//     return scale / 90;
// };

export const panelMask = () => {
    const bootstrap = () => {
        const maskElement = querySelectorOne<HTMLDivElement>(".panels .mask");

        if (!maskElement) {
            return;
        }

        const maskInnerElement = findForceOne<HTMLDivElement>(maskElement, ".mask-inner");

        // Calculate the Top offset to match the scroll when on a 'page' between 1 and 5
        const deriveTopOffset = deriveTotalPanelsOffsetFactory(1, 5, linear, linear);

        // Function to transit from 0 to 1 with a buffer
        const zeroToOneWithBuffer = lockedEaser(linear);

        const rotationAtPage = [0, 0, 90, 180, 270, 360, 360];

        onScrollChange.onScrollChange((scrollState) => {
            css(maskElement, {
                top: `${deriveTopOffset(scrollState.currentPage)}vh`,
            });
            const progressThroughPage = scrollState.currentPage % 1;
            const bufferedProgress = zeroToOneWithBuffer(progressThroughPage);
            const currentTransittingPage = Math.floor(scrollState.currentPage);
            const startRotation = rotationAtPage[currentTransittingPage];
            const endRotation = rotationAtPage[currentTransittingPage + 1];
            const rotation = toRange(bufferedProgress, startRotation, endRotation);
            // const relativeScaleIntensity = rotationToRelativeScaleIntensity(rotation);
            // const scale = toRange(relativeScaleIntensity, 1, 2);
            const transform = `rotate(${rotation}deg)`;

            css(maskInnerElement, {
                transform,
            });
        });
    };

    return {
        bootstrap,
    };
};
